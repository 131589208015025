import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import './Header.css'


const HeaderItem = styled(Link)`
text-decoration: none;
color: #FDFEFF;
display: inline-block;
white-space: nowrap;
margin: 0 1rem;
font-family: Nunito Bold;
font-size: 1.35vw;
font-weight:500;

line-height: 1.82vw;
&.active{
  position: relative;
}
:hover{
  color: #FDFEFF;
}
@media (max-width: 767.78px) {
  padding: 7vw 0;
  font-size: 15pt;
  z-index: 6;
  font-weight:500;
  color: white; 
  margin-top:-2%;
  
  &.active{
    background-color: #B8272F;
    width:100%;
    margin-left:-1%;
    padding-left:7%;
  }
}
@media screen and (min-width:768px) and (max-width:1024px){
  padding: 5vw 0;
  font-size: 4.38vw;
  z-index: 6;
  color: white; 
  margin-top:2%;
  padding-left:4%;
  &.active{
    background-color: #B8272F;
    width:100%;
    margin-left:-1%;
    padding-left:7%;
  }
}
`

const HeaderLinks = (props) => {
  return (
    <>
      <HeaderItem to="/" exact activeClassName="active">Home</HeaderItem>
      <HeaderItem to="/about" exact activeClassName="active">About</HeaderItem>
      <HeaderItem to="/gambling-stats" exact activeClassName="active">WV Gambling Stats</HeaderItem>
      <HeaderItem to="/faq" exact activeClassName="active">FAQs</HeaderItem>
      <HeaderItem to="/news" exact activeClassName="active">News</HeaderItem>
      <HeaderItem to="/contact" exact activeClassName="active">Contact</HeaderItem>
    </>
  )
}

export default HeaderLinks