import React from 'react';
import './Footer.css';
import Logo from '../../images/logo.png';
import Mail from '../../images/mail.svg';
import Location from '../../images/location.svg';
import Facebook from '../../images/facebook.svg';
import Insta from '../../images/insta.svg';
import Twitter from '../../images/twitter.svg'
import 'bootstrap/dist/css/bootstrap.min.css';
import addToMailchimp from 'gatsby-plugin-mailchimp'

class Footer extends React.Component{
  constructor(props){
    super(props);
    this.state={
      name: '',
      email:'',
      feedbackMsg:''
    }
  }

  encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  handleChange = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value});
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    e.target.reset();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: this.encode({
        "form-name": "signupform",
        ...this.state,
      }),
    })
    addToMailchimp(this.state.email,{
      "FNAME": this.state.name,
      "SUBJECT":"Signupform"
    })
    .then((response) => {
      console.log(response);
      this.setState({
        feedbackMsg: "Form submitted successfully!",
      })
    })
    .catch((error) => {
      console.log(error);
      this.setState({
        feedbackMsg: "Email already exists",
      })
    })
    console.log('Form submitted');
  }

  render(){
    return(
      <>
        <div className="footer">
          <div className="row footercontainer">
            <div className="col-lg-3 footerdiv">
              <img src={Logo} alt="location" className="footerlogo"/>
              <div className="mailDiv">
                <img src={Mail} alt="location" className="mail"/>
                <text className="mailText">admin@propmellc.com</text>
              </div>
              <div className="locationDiv">
                <img src={Location} alt="location" className="location"/>
                <text className="mailText">West Virginia, USA</text>
              </div>
            </div>
            <div className="col-lg-3 footerdiv">
              <div className="footerHeaderDiv">
                <text className="footerHeader">Site Map</text>
              </div>
              <text className="home" onClick={()=>{window.location.href='/'}}>Home</text>
              <text className="home" onClick={()=>{window.location.href='/about'}}>About</text>
              <text className="home" onClick={()=>{window.location.href='/gambling-stats'}}>Gambling Stats</text>
              <text className="home" onClick={()=>{window.location.href='/news'}}>FAQs</text>
              <text className="home" onClick={()=>{window.location.href='/news'}}>News</text>
              <text className="home" onClick={()=>{window.location.href='/contact'}}>Contact</text>
            </div>
            <div className="col-lg-6 footerdiv">
            <text className="footerHeader">Sign Up to Our Newsletter</text>
              <div className="inputsDiv" onSubmit={this.handleSubmit}>
                <form name="signupform" method="POST">
                  <input className="inputname" type="text" placeholder="Name" name="name" id="NAME" onChange={this.handleChange} required/>
                  <input className="emailInput" type="text" placeholder="Email Address" name="email" id="email" onChange={this.handleChange} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" size="30" required/>
                  <button className="signup">SIGN UP</button>
                </form>
              </div>
              <text className="home">{this.state.feedbackMsg}</text>
              <div className="followusDiv">
                <text className="footerHeader">Follow Us</text>
              </div>
              <div className="socialLinksDiv">
                {/* <img src={Facebook} alt="location" className="socialLink"/>
                <img src={Insta} alt="location" className="socialLink"/> */}
                <a href="https://mobile.twitter.com/PropMe_LLC" target="_blank" rel="noreferrer" ><img src={Twitter} alt="location" className="socialLink"/></a>
              </div>
            </div>
          </div>
        </div>
        <div className="subFooter">
          <text className="subFooterText">Terms of Service | Privacy Policy</text>
          <text className="subFooterText">© Copyright 2021 DET - All Rights Reserved</text>
        </div>
      </>
    )
  }
}

export default Footer