import React from "react"
import logo from '../../images/logo.png'
import logombl from '../../images/logombl.png'
import './styles.css'

const Logo = () => {
  return (
    <div className="logo">
      <a href="/" ><img src={logo} alt="detLogo" className="logoweb"/></a>
      <a href="/" ><img src={logombl} alt="detLogo" className="logombl"/></a>
    </div>
  )
}
export default Logo